<template>
  <div class="page-container" id="page-container">
    <div ref="toolbar" class="toolbar">
      <el-row>
        <el-col :span="12">
          <el-button type="primary" @click="insertProcessNew">添加工序</el-button>
        </el-col>
        <el-col :span="12">
          <el-input
            v-model.trim="query.key"
            placeholder="名称搜索"
            clearable
            style="float: right; width: 300px"
            @clear="getProcedureLibraryPage()"
          >
            <el-button slot="append" type="primary" @click="getProcedureLibraryPage()">查询</el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>
    <div ref="mainContent" class="process-category-content">
      <el-container>
        <el-main>
          <div>

            <el-table
              :data="procedureLibraryList"
              :row-class-name="getRowClass"
              style="width: 100%;cursor: pointer;">
              <el-table-column type="expand" >
                <template slot-scope="props">
                  <div style="background-color: #e8e7e7;padding: 10px;">
                    <span style="font-size: 15px;">工序详规管理</span>
                    <el-button type="primary" @click="openProcedureAddModalHandler(props.row.id)" style="margin-left: 20px;">
                      添加详规
                    </el-button>
                    <el-button type="primary" @click="deleteProcedureHandler"  v-if="deleteProcedureBtnVisible">
                      删除详规
                    </el-button>
                  </div>
                  <el-table
                    :data="props.row.procedureProvideInfoList"
                    @selection-change="workingProcedureChangerHandler"
                    @row-click="workingProcedureRowClickHandler"
                    style="width: 100%;cursor: pointer;">
                    <el-table-column
                      type="selection"
                      width="60">
                    </el-table-column>
                    <el-table-column  label="顺序" width="80" type="index" align="center"></el-table-column>
                    <el-table-column prop="name" label="内容" show-overflow-tooltip ></el-table-column>
                    <el-table-column prop="workingMinute" label="工时" width="60"></el-table-column>
                    <el-table-column prop="sortId" label="排序" width="60"></el-table-column>
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip width="120"></el-table-column>
                  </el-table>
                </template>
              </el-table-column>

              <el-table-column  width="80" type="index" align="center"></el-table-column>
              <el-table-column prop="id" label="ID" width="100" ></el-table-column>
              <el-table-column prop="name" label="名称" show-overflow-tooltip ></el-table-column>
              <el-table-column prop="auxiliaryMinute" label="辅助工时"  width="90"></el-table-column>
              <el-table-column prop="basicMinute" label="基础工时"  width="90"></el-table-column>
              <el-table-column prop="peopleNumber" label="人数"  width="90"></el-table-column>
<!--              <el-table-column prop="equipmentNumber" label="设备资源" width="120"></el-table-column>-->
              <el-table-column prop="skillName" label="技能组" width="150"></el-table-column>
              <el-table-column prop="belongingName" label="归属工序" show-overflow-tooltip></el-table-column>

              <el-table-column align="center" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button size="mini" type="text" icon="el-icon-edit" title="修改" @click="handleProcessUpdate(scope.row)"></el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>


          <div ref="pagebar" class="pagebar">
            <el-pagination
              :page-sizes="[10, 20, 50, 100]"
              :current-page="currentPage"
              :page-size="query.size"
              :total="query.total"
              layout="total, sizes, prev, pager, next, jumper"
              @current-change="handlePageChange"
              @size-change="handleLimitChange"
            />
          </div>

          <br />
          <br />
          <procedure-add v-model="procedureAddModalVisiable" :process-id="processId" :offset-height="mainHeight" @callback="procedureAddCallback"></procedure-add>
          <procedure-edit v-model="procedureEditModalVisiable" :procedure-id="procedureId" :offset-height="mainHeight" @callback="procedureEditCallback"></procedure-edit>

          <process-add v-model="processAddModalVisiable"  :offset-height="mainHeight" @callback="processAddCallback"></process-add>
          <process-edit v-model="processEditModalVisiable" :process-new-id="processNewId"  :offset-height="mainHeight" @callback="processEditCallback"></process-edit>

        </el-main>

      </el-container>
    </div>



  </div>
</template>

<script>
  import Avatar from "@/components/Avatar";
  import ProcedureLibrary from '@/api/procedure-library'
  import ProcedureProvide from '@/api/procedure-provide'
  import ProcedureAdd from "./add";
  import ProcedureEdit from "./edit";
  import ProcessAdd from "./process-add";
  import ProcessEdit from "./process-edit";

  export default {
    name: "ProcedureLibrary",
    components: { Avatar,ProcedureAdd,ProcedureEdit,ProcessAdd,ProcessEdit },
    data() {
      return {
        processId: null,
        procedureAddModalVisiable: false,
        deleteProcedureBtnVisible: false,
        procedureEditModalVisiable: false,
        processAddModalVisiable: false,
        processEditModalVisiable: false,
        processNewId: null,
        procedureSelectData: [],
        mainHeight: 300,
        procedureId: null,

        ruleValidate: {
          name: [
            { required: true, message: '请输入菜单名称', trigger: 'blur' }
          ]
        },

        query: {
          page: 0,
          size: 10,
          totalPages: 0,
          total: 0,
          key: '',
        },
        currentPage: 1,
        procedureLibraryList: [],

      };
    },
    mounted() {
      this.getProcedureLibraryPage();
    },
    methods: {



      getProcedureLibraryPage() {
        // 获取菜单列表
        const that = this;
        that.axios.get(ProcedureLibrary.getProcedureLibraryAll, { params: that.query }).then((data) => {
          that.procedureLibraryList = data.content;
          that.query.total = data.totalElements
          that.query.page = data.number;
          that.query.size = data.size;
          that.query.totalPages = data.totalPages;
        });
      },

      // 切页
      handlePageChange(val) {
        this.currentPage = val;
        this.query.page = val - 1;
        this.getProcedureLibraryPage();
      },
      // 切页码
      handleLimitChange(val) {
        this.currentPage = 1;
        this.query.size = val;
        this.getProcedureLibraryPage();
      },



      getRowClass(row){
        let data = row.row
        let res = []
        if(data.isSubProcess === 1){
          res.push('row-expand-has')
          return res
        }else {
          res.push('row-expand-unhas')
          return res
        }
      },


      processAddCallback () {
        // 添加文章对话框回调事件
        this.getProcedureLibraryPage();
        this.processAddModalVisiable = false
      },

      processEditCallback () {
        // 添加文章对话框回调事件
        this.getProcedureLibraryPage();
        this.processEditModalVisiable = false
      },
      procedureAddCallback () {
        // 添加文章对话框回调事件
        this.getProcedureLibraryPage();
        this.procedureAddModalVisiable = false
      },
      procedureEditCallback(){
        // 修改文件对话框回调事件
        this.getProcedureLibraryPage();
        this.procedureEditModalVisiable = false
      },

      openProcedureAddModalHandler (id) {
        // 打开添加对话框
        this.processId = id
        if (this.processId !== null) {
          this.procedureAddModalVisiable = true
        } else {
          this.$message('请选择所属子工艺')
        }
      },

      workingProcedureRowClickHandler(row, column, event){
        // 行点击事件
        this.procedureId = row.id
        this.procedureEditModalVisiable = true
      },

      workingProcedureChangerHandler(data){
        // 行选中变化事件
        this.procedureSelectData = data
        if (this.procedureSelectData.length > 0) {
          this.deleteProcedureBtnVisible = true
        }else {
          this.deleteProcedureBtnVisible = false
        }

      },

      deleteProcedureHandler () {
        // 删除工序
        let that = this
        if (that.procedureSelectData.length === 0) {
          that.$message.warning('请至少勾选一条详规')
          return false
        }
        that.$confirm("是否要删除所选详规?", "删除详规提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = []
          that.procedureSelectData.forEach((item, index) => {
            params.push({ id: item.id })
          })
          that.axios.post(ProcedureProvide.deleteProcedureProvide, params).then(() => {
            that.procedureSelectData = []
            this.getProcedureLibraryPage();
            that.$message.success("删除成功");
          })
        }).catch(() => {
          that.$message({
            type: 'info',
            message: '取消删除'
          });
        });
      },


      insertProcessNew(){
        let that = this
        that.processAddModalVisiable = true
      },

      handleProcessUpdate(row){
        // 修改
        let that = this
        that.processNewId = row.id
        that.processEditModalVisiable = true
      },


    },
  };
</script>

<style lang="scss" scoped>
  .process-category-content{
    position: relative;
    overflow: hidden;
    height: calc(100vh - 210px);
    border: 1px solid #e9ecf0;
    .process-category-aside{
      overflow: auto;
      height: calc(100vh - 210px);
      background-color: #fff;
      border-right: 1px solid #e9ecf0;
      padding: 8px 24px 0px 24px;
      margin: 0px;
    }
    ::v-deep .row-expand-unhas .el-table__expand-icon > .el-icon{
      display: none;
    }

    ::v-deep .row-expand-unhas .el-table__expand-icon{
      visibility: hidden !important;
    }
    .el-main{
      height: calc(100vh - 210px);
    }

  }


</style>
