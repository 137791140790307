<template>
  <div class="process-add-layout">
    <el-dialog title="添加工序" :visible.sync="modalVisiable" width="30%" :height="modalHeight" top="50px" @on-visible-change="modalVisibleChangeHandler">
      <el-form ref="procedureLibraryForm" :model="procedureLibraryInfo" :rules="ruleValidate" label-position="right" label-width="120px">
        <el-form-item prop="name" label="名称">
          <el-input v-model="procedureLibraryInfo.name" placeholder="名称"></el-input>
        </el-form-item>

        <el-form-item prop="front" label="选择归属工序">
          <el-select v-model="procedureLibraryInfo.belongingProcess" filterable placeholder="选择归属工序" style="width:200px;">
            <el-option
              v-for="item in processSub"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="front" label="选择技能组">
          <el-select v-model="procedureLibraryInfo.skillId" placeholder="选择技能组" style="width:200px;">
            <el-option
              v-for="item in skillList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="是否是小工序" prop="isSubProcess">
          <el-input v-model="procedureLibraryInfo.isSubProcess" placeholder="请输入是否是小工序" style="width: 200px;"></el-input>
          <span style="color: red">详规需挂在小工序  0:否  1：是</span>
        </el-form-item>

        <el-form-item prop="auxiliaryHours" label="辅助工时">
          <el-input v-model="procedureLibraryInfo.auxiliaryMinute" placeholder="辅助工时"></el-input>
        </el-form-item>
        <el-form-item prop="basicHours" label="基本工时">
          <el-input v-model="procedureLibraryInfo.basicMinute" placeholder="基本工时"></el-input>
        </el-form-item>
<!--        <el-form-item prop="price" label="价格">-->
<!--          <el-input v-model="procedureLibraryInfo.price" placeholder="价格"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item prop="peopleNumber" label="人数">
          <el-input v-model="procedureLibraryInfo.peopleNumber" placeholder="人力资源"></el-input>
        </el-form-item>
<!--        <el-form-item prop="equipmentNumber" label="设备数">-->
<!--          <el-input v-model="procedureLibraryInfo.equipmentNumber" placeholder="设备资源"></el-input>-->
<!--        </el-form-item>-->
        <el-row>
          <el-col>
            <div class="modal-footer-center">
              <el-button type="primary" @click="processAddHandler">保存</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>


  </div>
</template>

<script>
  import ProcedureLibrary from '@/api/procedure-library'
  import Skill from '@/api/skill'

  export default {
    name: 'ProcessAdd',
    components: {

    },
    props: {
      value: Boolean,
      offsetHeight: Number,
    },
    data () {
      return {
        modalHeight: 0,
        processSub: [],

        modalVisiable: false,
        submitDisabled: false,
        editorHeight: 0,
        skillList: [],
        procedureLibraryInfo: {
          id: '',
          name: '',
          auxiliaryMinute: null,
          basicMinute: null,
          price: null,
          peopleNumber: null,
          equipmentNumber: null,
          isSubProcess: null,
          belongingProcess: null,
          skillId: null,

        },
        ruleValidate: {
          name: [
            { required: true, message: '请输入工序名称', trigger: 'blur' }
          ],
          isSubProcess: [
            { required: true, message: '请确认是否是小工序', trigger: 'blur' }
          ]
        }
      }
    },
    watch: {
      value (val) {
        // 获取父级作用域变量值,初始化对话框
        this.modalVisiable = val
      },
      offsetHeight (val) {
        this.modalHeight = val
        this.editorHeight = val - 293
      },
      modalVisiable (val) {
        this.submitDisabled = false
        this.$emit('input', val)
        if (val) {
          this.procedureLibraryInfo = {
            id: '',
            name: '',
            auxiliaryMinute: null,
            basicMinute: null,
            price: null,
            peopleNumber: null,
            equipmentNumber: null,
            isSubProcess: null,
            belongingProcess: null,
            skillId: null,
          }
        }
      }
    },
    mounted () {
      // ProcedureLibrary
      this.getProcedureList()
      this.getSkillInfoListData();
    },
    methods: {

      getSkillInfoListData() {
        const that = this;
        this.axios
          .get(Skill.getSkillInfoList,)
          .then((data) => {
            that.skillList = data;
          })
      },

      getProcedureList(){
        const that = this;
        that.processSub = [];
        this.axios
          .get(ProcedureLibrary.getProcedureListByMax,)
          .then((data) => {
            that.processSub = data
          })

      },

      processAddHandler () {
        // 保存
        let that = this
        that.$refs['procedureLibraryForm'].validate((valid) => {
          if (valid) {
            that.axios.post(ProcedureLibrary.insertProcedureLibrary, that.procedureLibraryInfo).then(res => {
              that.$message.success("保存成功");
              that.$emit('callback')
            })
          } else {
            that.$message.error('请检查输入!');
          }
        })
      },


      modalVisibleChangeHandler (val) {
        // 对话框显示状态
        this.modalVisiable = val
      }
    }
  }
</script>

<style lang="scss" scoped>
  .process-add-layout{
    background: #fff;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }
  .form-left-pane{
    padding: 0px 10px 10px 0px;
  }
  .process-add-layout .el-form-item{
    margin-bottom: 20px;
  }

  .img-tools{
    padding:5px 10px;
    background: rgba(0,0,0,0.03);
    a{
      color: #000;
    }
  }
  .modal-footer-center{
    background: #fff;
    text-align: center;
  }
</style>
