<template>
  <div class="working-procedure-edit-layout">
    <el-dialog title="编辑详规" :visible.sync="modalVisiable" width="30%" :height="modalHeight" top="50px" @on-visible-change="modalVisibleChangeHandler">
      <el-form ref="procedureForm" :model="procedureInfo" :rules="ruleValidate" label-position="right" label-width="120px">
        <el-form-item prop="name" label="内容">
          <el-input v-model="procedureInfo.name" placeholder="内容"></el-input>
        </el-form-item>
        <el-form-item prop="workingMinute" label="工时">
          <el-input v-model="procedureInfo.workingMinute" placeholder="工时"></el-input>
        </el-form-item>
        <el-form-item prop="sortId" label="排序">
          <el-input v-model="procedureInfo.sortId" placeholder="排序"></el-input>
        </el-form-item>
        <el-form-item prop="remarks" label="备注">
          <el-input v-model="procedureInfo.remarks" placeholder="备注"></el-input>
        </el-form-item>
        <el-row>
          <el-col>
            <div class="modal-footer-center">
              <el-button type="primary" @click="procedureEditHandler">保存</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import ProcedureProvide from '@/api/procedure-provide'

  export default {
    name: 'ProcedureEdit',
    components: {
    },
    props: {
      value: Boolean,
      offsetHeight: Number,
      procedureId: Number
    },
    data () {
      return {
        spinShow: false,
        modalHeight: 0,
        modalVisiable: false,
        submitDisabled: false,
        editorHeight: 0,
        procedureInfo: {
          id: '',
          name: '',
          workingMinute: null,
          sortId: '',
          procedureId: null,
          remarks: '',
        },
        ruleValidate: {
          name: [
            { required: true, message: '请输入工序内容', trigger: 'blur' }
          ],
          workingMinute: [
            { required: true, message: '请输入工时', trigger: 'blur' }
          ],
          sortId: [
            { required: true, message: '请输入排序', trigger: 'blur' }
          ]
        }
      }
    },
    watch: {
      value (val) {
        // 获取父级作用域变量值,初始化对话框
        this.modalVisiable = val
      },
      offsetHeight (val) {
        this.modalHeight = val
        this.editorHeight = val - 293
      },
      modalVisiable (val) {
        this.submitDisabled = false
        this.$emit('input', val)
        if (val) {
          this.getProcedureInfo()
        }
      }
    },
    mounted () {

    },
    methods: {
      getProcedureInfo () {
        // 获取文章信息
        let that = this
        that.axios.get(ProcedureProvide.getProcedureProvideOne, {params: { id: that.procedureId }}).then(data => {
          that.procedureInfo = data
        })
      },
      procedureEditHandler () {
        // 保存
        let that = this
        that.$refs['procedureForm'].validate((valid) => {
          if (valid) {
            that.axios.post(ProcedureProvide.updateProcedureProvide, that.procedureInfo).then(res => {
              that.$message.success("保存成功");
              that.$emit('callback')
            })
          } else {
            that.$message.error('请检查输入!');
          }
        })
      },


      modalVisibleChangeHandler (val) {
        // 对话框显示状态
        this.modalVisiable = val
      }
    }
  }
</script>

<style lang="scss" scoped>
  .working-procedure-edit-layout{
    background: #fff;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }
  .form-left-pane{
    padding: 0px 10px 10px 0px;
  }
  .working-procedure-edit-layout .el-form-item{
    margin-bottom: 20px;
  }
  .img-tools{
    padding:5px 10px;
    background: rgba(0,0,0,0.03);
    a{
      color: #000;
    }
  }
  .modal-footer-center{
    background: #fff;
    text-align: center;
  }
</style>
